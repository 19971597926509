


















































































































































import {Vue, Component, Prop} from 'vue-property-decorator'
import MyWalletTotalBalanceCard from '@/components/myWallet/MyWalletTotalBalanceCard.vue'
import MyWalletAddressCard from '@/components/myWallet/MyWalletAddressCard.vue'
import MyWalletUnclaimedGasSection from '@/components/myWallet/MyWalletUnclaimedGasSection.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {$} from '@/facade'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  computed: {
    $() {
      return $
    },
  },
  components: {
    MyWalletUnclaimedGasSection,
    MyWalletTotalBalanceCard,
    MyWalletAddressCard,
  },
})
export default class MyWalletSection extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  onClickMyWallet() {
    if (!$.walletAdapter.n3Address) {
      this.openModal()
      return
    }

    this.cacheAssist.populateWallet()
  }

  openModal() {
    this.$modal.open('connectWalletModal')
  }

  onClickWatchOnlyWallet() {
    if ($.walletAdapter.n3Address && !$.watchWallet.address) {
      this.cacheAssist.populateWatchWallet($.walletAdapter.n3Address)
      return
    }

    if ($.watchWallet.address) {
      this.cacheAssist.populateWatchWallet($.watchWallet.address)
      return
    }
  }
}
