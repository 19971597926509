var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"items-start w-full verti"},[_c('p',{staticClass:"mb-9 sm:mb-10 px-10 sm:px-0 font-bold text-2xl text-center text-regent-gray sm:text-left sm:text-xl uppercase self-start"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.chooseTokenToReceive' ))+" ")]),_c('div',{staticClass:"items-center gap-y-2 w-full horiz"},[(_vm.token && _vm.isTokenToUseIndivisible)?_c('div',{staticClass:"w-full max-w-1/2 sm:max-w-3/5 verti"},[_c('div',{staticClass:"items-center mb-1 horiz"},[_c('span',{staticClass:"pr-2 max-w-3/4 font-bold text-3xl text-slate-blue truncate"},[_vm._v(" "+_vm._s(_vm.amount || '0.00')+" ")]),_c('img',{staticClass:"w-7 h-7 object-contain",attrs:{"alt":"lock icon","src":require("@/assets/img/lock.svg")}})]),(_vm.showDollarAmount)?_c('span',{staticClass:"pr-6 w-full font-medium text-regent-gray text-xl truncate"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.priceFormat', { price: _vm.amountInDollar, } ))+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex-grow swap-input-select__select-container input-group input-group--select"},[_c('vue-multiselect',{staticClass:"multiselect--hide-checkbox multiselect--show-placeholder input-group__input",class:{
          'multiselect--invalid': _vm.selectIsValid === false,
        },attrs:{"allowEmpty":false,"clearOnSelect":false,"closeOnSelect":true,"multiple":false,"options":_vm.options,"searchable":false,"showLabels":true,"value":_vm.token,"deselectLabel":"","placeholder":"Select token","selectLabel":"","trackBy":"symbol"},on:{"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
        var option = ref.option;
return [_c('div',{staticClass:"items-center-center gap-x-2 mr-4 px-2 horiz"},[(option.imageUrl)?_c('img',{staticClass:"shadow-md mr-3 rounded-full w-12 sm:w-14 h-12 sm:h-14",attrs:{"src":option.imageUrl,"alt":"token icon"}}):_vm._e(),_c('span',{staticClass:"font-medium text-2xl text-slate-blue sm:text-3xl"},[_vm._v(" "+_vm._s(option.symbol)+" ")])])]}},{key:"option",fn:function(ref){
        var option = ref.option;
return [_c('div',{staticClass:"items-center gap-x-2 hover:font-bold horiz"},[(option.imageUrl)?_c('img',{staticClass:"shadow-md mr-3 rounded-full w-12 sm:w-14 h-12 sm:h-14",attrs:{"src":option.imageUrl,"alt":"token icon"}}):_c('div',{staticClass:"mr-3 w-14 h-13"}),_c('span',{staticClass:"text-2xl sm:text-3xl",class:{
                'font-bold text-black':
                  _vm.token &&
                  _vm.token.marketInformation &&
                  option.hash === _vm.token.marketInformation.hash,
                'font-medium text-slate-blue':
                  _vm.token === null ||
                  (_vm.token &&
                    _vm.token.marketInformation &&
                    option.hash !== _vm.token.marketInformation.hash),
              }},[_vm._v(" "+_vm._s(option.symbol)+" ")])])]}}])})],1)]),(_vm.token && !_vm.isTokenToUseIndivisible)?_c('div',{staticClass:"mt-10 w-full"},[_c('div',{staticClass:"items-center-center border-2 bg-white mb-4 px-7 py-2 border-solid rounded-full w-full cursor-text horiz",class:{
        'border-light-cyan-blue': _vm.inputIsValid === null,
        'border-coral-red': _vm.inputIsValid === false,
        'border-primary': _vm.inputIsValid === true,
      },on:{"click":_vm.focusOnInput}},[_c('swap-input',{staticClass:"swap-input-select__input",attrs:{"token":_vm.token,"value":_vm.amount},on:{"input":_vm.handleInput}})],1),_c('div',{staticClass:"justify-between horiz"},[(_vm.showDollarAmount)?_c('p',{staticClass:"ml-2 font-medium text-left text-slate-blue text-xl"},[_vm._v(" "+_vm._s(_vm.$translate( 'components.MyWalletSwapTokenToReceiveInputAndSelect.priceFormat', { price: _vm.amountInDollar, } ))+" ")]):_vm._e(),(!_vm.isTokenToReceiveIndivisible)?_c('tooltip',{attrs:{"label":_vm.$translate('components.MyWalletSwapTokenToUseInput.estimated'),"text":_vm.$translate(
            'components.MyWalletSwapTokenToUseInput.estimatedTooltip'
          )},on:{"onMobileOpen":_vm.setMobileTooltip}}):_vm._e()],1)]):_vm._e(),_c('tooltip-mobile-content',{staticClass:"mt-2",attrs:{"show":_vm.mobileTooltipIsVisible,"text":_vm.$translate('components.MyWalletSwapTokenToUseInput.estimatedTooltip')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }