
























































































import {Component, Prop, Watch} from 'vue-property-decorator'
import MyWalletClaimGasModal from '@/components/myWallet/MyWalletClaimGasModal.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {$} from '@/facade'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  computed: {
    $translate() {
      return $.translate
    },
  },
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    MyWalletClaimGasModal,
  },
})
export default class MyWalletUnclaimedGasSection extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  openMobileDisclaimer = false

  get isFeeGreaterThanUnclaimedGas() {
    return (
      Number(this.cacheAssist.currentWallet.fee) >=
      Number(this.cacheAssist.currentWallet.unclaimedGas)
    )
  }

  get isClaimGasButtonDisabled() {
    return (
      this.cacheAssist.isWatchWalletInUse || this.isFeeGreaterThanUnclaimedGas
    )
  }

  set isClaimGasButtonDisabled(value: boolean) {
    this.isClaimGasButtonDisabled = value
  }

  get popoverText() {
    if (this.cacheAssist.isWatchWalletInUse) {
      return $.translate(
        'components.MyWalletUnclaimedGas.watchWalletDisclaimer'
      )
    }

    return $.translate('components.MyWalletUnclaimedGas.feeDisclaimer')
  }

  setOpenMobileDisclaimer(val: boolean) {
    this.openMobileDisclaimer = val
  }

  openClaimGasModal() {
    this.$modal.open('myWalletClaimGasModal')
  }
}
