





















































































































































































































































































































































































































import {Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import {InputText, InputCheckbox} from '@simpli/vue-input'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {EnvHelper} from '@/helpers/EnvHelper'
import MyWalletGasCalculatorFooter from '@/components/myWallet/MyWalletGasCalculatorFooter.vue'
import {PropType} from 'vue'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {$} from '@/facade'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    MyWalletGasCalculatorFooter,
    InputText,
    InputCheckbox,
    VueMultiselect,
  },
})
export default class MyWalletGasCalculatorCard extends Mixins(MixinScreenSize) {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist
  @Prop({type: Boolean, required: true}) isChangeVoteDisabled!: boolean
  @Prop({type: String, required: true}) popoverText!: string
  @Prop({type: String, required: true}) buttonTitleText!: string

  neoOwnedInputValue = '0'
  useNeoBurgerInputValue = false
  selectedCandidateInputValue: GasCalculatorTarget | null = null

  openMobileDisclaimer = false

  get isSelectDisabled() {
    return this.useNeoBurgerInputValue
  }

  setOpenMobileDisclaimer(val: boolean) {
    this.openMobileDisclaimer = val
  }

  onChangeNeoInput(event: Event) {
    const value = (event.target as HTMLInputElement).value.replace(
      /[^0-9]/g,
      ''
    )

    const parsedValue = parseInt(value)

    let newValue = value

    if (
      value.length - EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY.length > 1 &&
      parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)
    ) {
      newValue = EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY
    } else if (parsedValue > Number(EnvHelper.VUE_APP_NEO_TOTAL_SUPPLY)) {
      newValue = value.slice(0, -1)
    }

    this.neoOwnedInputValue = newValue
  }

  showSquareSelected(option: GasCalculatorTarget) {
    return this.cacheAssist.candidateVoted.publicKey === option.publicKey
  }

  onSelectNeoBurger(value: boolean) {
    this.useNeoBurgerInputValue = value
    this.$emit('onSelectNeoBurger', value)
  }

  onSelectCandidate(value: GasCalculatorTarget) {
    this.selectedCandidateInputValue = value
    this.$emit('onSelectCandidate', value)
  }

  openChangeVoteModal() {
    this.$emit('openChangeVoteModal')
  }

  @Watch('cacheAssist.candidateVoted')
  handleChangeSelectedCandidateProp() {
    this.selectedCandidateInputValue = this.cacheAssist.candidateVoted
  }

  @Watch('cacheAssist.neoBalance')
  handleChangeNeoBalanceProp() {
    this.neoOwnedInputValue = String(this.cacheAssist.neoBalance)
  }
}
