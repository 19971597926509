


































































import {Component, Prop, Watch} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import MyWalletTotalBalanceCard from '@/components/myWallet/MyWalletTotalBalanceCard.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {$} from '@/facade'

@Component({
  components: {MyWalletTotalBalanceCard, PercentageProgress},
})
export default class MyWalletAddressCard extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  addressComputed = ''
  fullAddress = ''

  get addressFormatted() {
    if ($.walletAdapter.n3Address && !$.watchWallet.address) {
      this.fullAddress = $.walletAdapter.n3Address
    } else if (!$.walletAdapter.n3Address && $.watchWallet.address) {
      this.fullAddress = $.watchWallet.address
    } else if ($.walletAdapter.n3Address) {
      this.fullAddress = $.walletAdapter.n3Address
    }

    if (this.isLargeDesktop) {
      return this.fullAddress
    } else if (
      this.isLessThanTablet ||
      this.isTablet ||
      this.isSmallDesktop ||
      this.isDesktop
    ) {
      return this.fullAddress?.trim()
        ? `${this.fullAddress
            .trim()
            .slice(0, 9)}...${this.fullAddress.trim().slice(-9)}`
        : null
    }

    return this.fullAddress
  }

  async handleUpdateWatchWallet() {
    await this.cacheAssist.populateWatchWallet(this.addressComputed)
  }

  copyToClipboard() {
    if (!this.cacheAssist.connectedAddress) return

    this.$utils.copyToClipboard(this.cacheAssist.connectedAddress)
    this.$toast.info('system.info.copiedToClipboard')
  }
}
