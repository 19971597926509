









































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'

@Component({
  components: {PercentageProgress},
})
export default class MyWalletTotalBalanceCard extends Vue {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist
}
