


























































































































import {Mixins, Prop, Watch} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import Component from 'vue-class-component'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import TooltipMobileContent from '@/components/tooltip/TooltipMobileContent.vue'
import GasCalculatorFooter from '@/components/gasCalculator/GasCalculatorFooter.vue'
import GasCalculatorTable from '@/components/gasCalculator/GasCalculatorTable.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {PropType} from 'vue'
import MyWalletGasCalculatorFooter from '@/components/myWallet/MyWalletGasCalculatorFooter.vue'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    MyWalletGasCalculatorFooter,
    GasCalculatorTable,
    GasCalculatorFooter,
    TooltipMobileContent,
    Tooltip,
  },
})
export default class MyWalletGasRewardsCard extends Mixins(MixinScreenSize) {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist
  @Prop({
    type: ((null as unknown) as Object) as PropType<GasCalculatorTarget | null>,
    required: true,
  })
  selectedCandidate!: GasCalculatorTarget
  @Prop({type: Boolean, required: true}) isChangeVoteDisabled!: boolean
  @Prop({type: String, required: true}) popoverText!: string
  @Prop({type: String, required: true}) buttonTitleText!: string

  dailyAmount = 0
  weeklyAmount = 0
  monthlyAmount = 0
  yearlyAmount = 0
  apr = 0

  openMobileDisclaimer = false

  setOpenMobileDisclaimer(val: boolean) {
    this.openMobileDisclaimer = val
  }

  calculateGasRewards() {
    const selectedCandidateDailyAmount =
      Number(this.selectedCandidate.dailyGasRate) * this.cacheAssist.neoBalance

    this.dailyAmount = selectedCandidateDailyAmount
    this.weeklyAmount = this.$utils.weeklyAmount(selectedCandidateDailyAmount)
    this.monthlyAmount = this.$utils.monthlyAmount(selectedCandidateDailyAmount)
    this.yearlyAmount = this.$utils.yearlyAmount(selectedCandidateDailyAmount)
    this.apr = Number(this.selectedCandidate.apr)
  }

  openChangeVoteModal() {
    this.$emit('openChangeVoteModal')
  }

  @Watch('selectedCandidate')
  onSelectedCandidateChange() {
    this.calculateGasRewards()
  }
}
