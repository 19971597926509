import {HttpExclude, RequestExpose} from '@simpli/serialized-request'
import {ExpansibleCollection} from '@simpli/resource-collection'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'
import axios, {AxiosResponse} from 'axios'
import {GMNftCollection} from '@/model/resource/GhostMarket/GMNftCollection'
import {EnvHelper} from '@/helpers/EnvHelper'

@HttpExclude()
export class GMNftCollectionCollection extends ExpansibleCollection<
  GMNftCollection
> {
  resource?: IGMNftCollectionCollectionResourceHolder

  @RequestExpose() ownerAddress: string | null = null

  constructor() {
    super(GMNftCollection)
  }

  queryAsPage() {
    return this.listNftCollectionCollection()
  }

  async listNftCollectionCollection(): Promise<void> {
    const baseUrl = `${EnvHelper.VUE_APP_BASE_GHOSTMARKET_URL}collections`
    const params = new URLSearchParams()

    if (this.ownerAddress) {
      params.append('owners[]', this.ownerAddress)
    }

    const url = `${baseUrl}?${params.toString()}`

    const {data} = await axios.get(url)

    const items = data.collections.map((collection: any) => {
      const nftCollection = new GMNftCollection()

      nftCollection.name = collection.name
      nftCollection.slug = collection.slug

      return nftCollection
    })

    this.items = items
  }
}

export interface IGMNftCollectionCollectionResourceHolder {}
