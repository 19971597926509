
































import {Vue, Component} from 'vue-property-decorator'

@Component({})
export default class MyWalletWatchOnlyDisclaimer extends Vue {
  onConnectWallet() {
    this.$modal.open('connectWalletModal')
  }

  onCloseDisclaimer() {
    this.$emit('closeDisclaimer')
  }
}
