var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap-token-to-use-input"},[_c('div',{staticClass:"bg-pearl-aqua bg-opacity-25 px-6 pt-7 pb-4 sm:pb-7 rounded-3xl verti"},[_c('p',{staticClass:"mb-6 sm:mb-9 px-8 sm:px-0 font-bold text-2xl text-center text-secondary sm:text-left sm:text-xl uppercase"},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletSwapTokenToUseInput.chooseAmountToUse'))+" ")]),_c('div',{staticClass:"justify-between items-center border-2 bg-white mb-5 px-7 py-2 border-solid rounded-full cursor-text horiz",class:{
        'border-light-cyan-blue': _vm.isValid === null,
        'border-coral-red': _vm.isValid === false,
        'border-primary': _vm.isValid === true,
        'bg-catskill-white': _vm.isTokenToReceiveIndivisible,
      },on:{"click":_vm.focusOnInput}},[_c('swap-input',{staticClass:"swap-token-to-use-input__input",attrs:{"token":_vm.token,"value":_vm.amount},on:{"input":_vm.handleInput}}),_c('div',{staticClass:"flex-shrink-0 items-center gap-x-2 horiz",on:{"click":_vm.focusOnInput}},[_c('div',{staticClass:"md:mx-3 mr-2 ml-4 rounded-full w-1 h-6 sm:h-8",class:{
            'bg-light-cyan-blue': _vm.isValid === null,
            'bg-coral-red': _vm.isValid === false,
            'bg-primary': _vm.isValid === true,
          }}),(_vm.token.imageUrl)?_c('img',{staticClass:"shadow-btn rounded-full w-10 sm:w-14 h-10 sm:h-14",attrs:{"src":_vm.token.imageUrl,"alt":"selected token icon"}}):_vm._e(),_c('span',{staticClass:"font-medium text-2xl text-slate-blue sm:text-3xl"},[_vm._v(" "+_vm._s(_vm.token.symbol)+" ")])])],1),_c('div',{staticClass:"justify-between items-center horiz"},[(_vm.showDollarAmount)?_c('span',{staticClass:"mr-8 font-medium text-slate-blue text-xl"},[_vm._v(" "+_vm._s(_vm.$translate('components.MyWalletSwapTokenToUseInput.priceFormat', { price: _vm.amountInDollar, }))+" ")]):_vm._e(),_c('swap-token-account-balance',{attrs:{"token-account-balance":_vm.tokenAccountBalance}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }