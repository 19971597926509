
























































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'

@Component({})
export default class MyWalletConnectAddress extends Vue {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  computedWatchWalletAddress: string = ''

  handleConnectWallet() {
    this.$modal.open('connectWalletModal')
  }

  async onClickConnectWatchWallet() {
    await this.cacheAssist.populateWatchWallet(this.computedWatchWalletAddress)
  }
}
