











































import {Component, Watch, Mixins, Prop} from 'vue-property-decorator'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {NeoHelper} from '@/helpers/NeoHelper'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import CandidateVoteModal from '@/components/candidateVote/CandidateVoteModal.vue'
import MyWalletGasCalculatorCard from '@/components/myWallet/MyWalletGasCalculatorCard.vue'
import MyWalletGasRewardsCard from '@/components/myWallet/MyWalletGasRewardCard.vue'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {$} from '@/facade'

@Component({
  components: {
    CandidateVoteModal,
    MyWalletGasCalculatorCard,
    MyWalletGasRewardsCard,
  },
})
export default class MyWalletGasCalculatorSection extends Mixins(
  MixinScreenSize
) {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  lastCandidateSelected: GasCalculatorTarget | null = null
  selectedCandidate: GasCalculatorTarget | null = null

  isNeoBurgerInUse = false

  get isChangeVoteDisabled() {
    return (
      !this.selectedCandidate ||
      this.isNeoBurgerSelected ||
      this.isSameCandidateSelected ||
      this.hasInsufficientNeo ||
      this.cacheAssist.isWatchWalletInUse
    )
  }

  get buttonTitleText() {
    return this.$translate('components.MyWalletGasCalculator.changeVote')
  }

  get popoverText() {
    if (!this.selectedCandidate) {
      return this.$translate(
        'components.MyWalletGasCalculator.unknownErrorDisclaimer'
      )
    }

    if (this.cacheAssist.isWatchWalletInUse) {
      return this.$translate(
        'components.MyWalletGasCalculator.watchWalletDisclaimer'
      )
    }

    if (this.isNeoBurgerSelected) {
      return `
      <p>
        ${this.$translate(
          'components.gasCalculator.gasCalculatorChangeVoteTooltipContent.useNeoBurgerText1'
        )}
        <span class="bg-pale-greenish-blue rounded-full text-slate-blue px-1 font-medium">
          ${this.$translate(
            'components.gasCalculator.gasCalculatorChangeVoteTooltipContent.questionMark'
          )}
        </span>
        ${this.$translate(
          'components.gasCalculator.gasCalculatorChangeVoteTooltipContent.useNeoBurgerText2'
        )}
      </p>
    `
    }

    if (this.isSameCandidateSelected) {
      return this.$translate(
        'components.MyWalletGasCalculator.sameCandidateSelectedDisclaimer'
      )
    }

    if (this.hasInsufficientNeo) {
      return this.$translate(
        'components.MyWalletGasCalculator.insufficientNeoDisclaimer'
      )
    }

    return this.$translate(
      'components.MyWalletGasCalculator.unknownErrorDisclaimer'
    )
  }

  get isNeoBurgerSelected() {
    if (!this.selectedCandidate) return false

    return (
      this.selectedCandidate.scriptHash ===
      this.cacheAssist.gasCalculatorTargetCollection.neoBurger?.scriptHash
    )
  }

  get isSameCandidateSelected() {
    if (!this.selectedCandidate) return false

    return (
      this.selectedCandidate.scriptHash ===
      this.cacheAssist.candidateVoted?.scriptHash
    )
  }

  get hasInsufficientNeo() {
    return this.cacheAssist.neoBalance < 1
  }

  openChangeVoteModal() {
    if (!this.selectedCandidate) return

    this.$modal.open('candidateVoteModal', {
      gasCurrentPriceInDollar: this.cacheAssist.gasPriceInDollar,
      selectedCandidateDailyAmount: Number(
        this.selectedCandidate?.dailyGasRate
      ),
      currentCandidateVotedDailyAmount:
        Number(this.cacheAssist.candidateVoted.dailyGasRate) *
        Number(this.cacheAssist.neoBalance),
      selectedCandidate: this.selectedCandidate,
      currentCandidateVoted: this.cacheAssist.candidateVoted,
    })
  }

  onSwapped() {
    if (!this.selectedCandidate) return

    this.cacheAssist.candidateVoted = this.selectedCandidate
  }

  onSelectCandidate(candidate: GasCalculatorTarget) {
    this.selectedCandidate = candidate
    this.lastCandidateSelected = candidate
  }

  onSelectNeoBurger() {
    this.isNeoBurgerInUse = !this.isNeoBurgerInUse

    if (this.isNeoBurgerInUse) {
      if (!this.cacheAssist.gasCalculatorTargetCollection.neoBurger) return

      this.selectedCandidate = this.cacheAssist.gasCalculatorTargetCollection.neoBurger
    } else {
      this.selectedCandidate = this.lastCandidateSelected
    }
  }

  @Watch('cacheAssist.candidateVoted', {immediate: true, deep: true})
  onSelectedCandidateChanged() {
    if (!this.cacheAssist.candidateVoted) return

    this.onSelectCandidate(this.cacheAssist.candidateVoted)
  }
}
